import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-0220/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-0220 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-0220/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-0220/' crumbLabel="IN-0220" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-0220-wideangle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0220-wideangle",
        "aria-label": "in 0220 wideangle permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0220 Wideangle`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3001 Indoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-3011/"
        }}>{`IN-3010/1 Indoor Camera`}</a></li>
      <li parentName="ul">{`IN-2904 Outdoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-2905_V2/"
        }}>{`IN-2905 V2 Outdoor Camera`}</a></li>
    </ul>
    <p><img parentName="p" {...{
        "src": "./Wideangle_Lense_2.2mm_-_Angle_of_View.png",
        "alt": "IN-0220 Wideangle"
      }}></img></p>
    <p>{`The angle of view of the 2.2mm wideangle lense. Field Angle (D x H x V): 98.2° x 78.7° x 60.7°.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/43810b1a88983f2a6414286c0b92b541/c08c5/Wideangle_2.2mm_IRcut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeVpuCqZP//EABgQAAMBAQAAAAAAAAAAAAAAAAACEgET/9oACAEBAAEFAlURdIcos66f/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BR//EABoQAAICAwAAAAAAAAAAAAAAAAABETEgM4H/2gAIAQEABj8CoqUa3x4f/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERQSExYYH/2gAIAQEAAT8hp10l7GY4peMWKVkPA0bsvwvBq0//2gAMAwEAAgADAAAAEBs//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EJiP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qg//EABoQAQEBAQEBAQAAAAAAAAAAAAERACFhUXH/2gAIAQEAAT8QSGq6MGeCgAAh5unoVMgu/fc+FPRpMs/IZ6Ew3//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43810b1a88983f2a6414286c0b92b541/e4706/Wideangle_2.2mm_IRcut.avif 230w", "/en/static/43810b1a88983f2a6414286c0b92b541/d1af7/Wideangle_2.2mm_IRcut.avif 460w", "/en/static/43810b1a88983f2a6414286c0b92b541/e6133/Wideangle_2.2mm_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/43810b1a88983f2a6414286c0b92b541/a0b58/Wideangle_2.2mm_IRcut.webp 230w", "/en/static/43810b1a88983f2a6414286c0b92b541/bc10c/Wideangle_2.2mm_IRcut.webp 460w", "/en/static/43810b1a88983f2a6414286c0b92b541/0ba47/Wideangle_2.2mm_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43810b1a88983f2a6414286c0b92b541/e83b4/Wideangle_2.2mm_IRcut.jpg 230w", "/en/static/43810b1a88983f2a6414286c0b92b541/e41a8/Wideangle_2.2mm_IRcut.jpg 460w", "/en/static/43810b1a88983f2a6414286c0b92b541/c08c5/Wideangle_2.2mm_IRcut.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/43810b1a88983f2a6414286c0b92b541/c08c5/Wideangle_2.2mm_IRcut.jpg",
              "alt": "IN-0220 Wideangle",
              "title": "IN-0220 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 2.2mm wideangle lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a74182765a6b0f25847eb6ecf08bb051/4b9ef/Tagsicht_2%2C2mm_with_IRfilter_(940nm).jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAW7VSQlIf//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMREv/aAAgBAQABBQLMnXSMrE13Ampk0sgn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAERAhIhIjFR/9oACAEBAAY/Aiqc2+ks2SGdZ//EABwQAQACAgMBAAAAAAAAAAAAAAEAEUFhIVFxgf/aAAgBAQABPyHKIGmBqrM9TIhiuxx1LiVbqK0t8n//2gAMAwEAAgADAAAAEADP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EGx//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAQACAgMBAAAAAAAAAAAAAREAITGhUXGxwf/aAAgBAQABPxDcjgo8EK9Zw+S9FuKwodYt3lAUNfcAPDHah+4/beSM/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a74182765a6b0f25847eb6ecf08bb051/e4706/Tagsicht_2%2C2mm_with_IRfilter_(940nm).avif 230w", "/en/static/a74182765a6b0f25847eb6ecf08bb051/d1af7/Tagsicht_2%2C2mm_with_IRfilter_(940nm).avif 460w", "/en/static/a74182765a6b0f25847eb6ecf08bb051/e1e16/Tagsicht_2%2C2mm_with_IRfilter_(940nm).avif 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a74182765a6b0f25847eb6ecf08bb051/a0b58/Tagsicht_2%2C2mm_with_IRfilter_(940nm).webp 230w", "/en/static/a74182765a6b0f25847eb6ecf08bb051/bc10c/Tagsicht_2%2C2mm_with_IRfilter_(940nm).webp 460w", "/en/static/a74182765a6b0f25847eb6ecf08bb051/de042/Tagsicht_2%2C2mm_with_IRfilter_(940nm).webp 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a74182765a6b0f25847eb6ecf08bb051/e83b4/Tagsicht_2%2C2mm_with_IRfilter_(940nm).jpg 230w", "/en/static/a74182765a6b0f25847eb6ecf08bb051/e41a8/Tagsicht_2%2C2mm_with_IRfilter_(940nm).jpg 460w", "/en/static/a74182765a6b0f25847eb6ecf08bb051/4b9ef/Tagsicht_2%2C2mm_with_IRfilter_(940nm).jpg 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a74182765a6b0f25847eb6ecf08bb051/4b9ef/Tagsicht_2%2C2mm_with_IRfilter_(940nm).jpg",
              "alt": "IN-0220 Wideangle",
              "title": "IN-0220 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test shot of the 2.2mm wideangle lense on a 1/4" CMOS sensor during the day.`}</p>
    <h2 {...{
      "id": "in-0220-wideangle-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0220-wideangle-lense-chart",
        "aria-label": "in 0220 wideangle lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0220 Wideangle Lense Chart`}</h2>
    <p>{`Lense chart for the 2.2mm wideangle for the 1/4" CMOS Sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "492px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f69575330d855de0874ec3b8e714fb3c/5c6e9/Lense_Chart_2_2mm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB0ElEQVQoz21SPW/bMBDVbyy6dbHTKclmeCnaZkmBzkWBtmtRZCvQ35AMRrqlWwIIMGSHkq0vitQHeXckHYhMlQx5g/BA3XuPvLuIiKy1zlkiMhOIsixjaZpsEpamxhg7wjjnrHWej/WRMSbZJLd3t1LKg4e1k8MIRHTOhV9aKwAIfBQjwPLs43Ix//P7l+z6tm0Zu7+4+Nl1k5d1Ho3geb7L8/2g9OFwQMRIa/3565dXb15/eP+u5jyOY6WG9TrWWgWxc24Y+rLK+74PRuF8FPd9B6iF7BApFPt658nIhGiquiRDw9BlGSvKgogexUr1ztlgZowJxiEBAHhTSSm6ri3KfcO51lrKVvtnh+SnyzwTm7aVVVU2oimrgvM69OmFa1v7lBy+VVWkGcuLfSMaAAgCPyc7FY/itpVhgP8DxfY+yTLWCGG91yRwzoWBBz6KAR7HiAib7Xq7TYQQRPRi2nMYYyK/YSbPszi+q+t60kxvC8qyrG5u/n37/mM2m5+cnM6P3l5f/40Qsaz2LGVEBBrCVgHAMPQAGhF8Rw1j6dXl1fn5p9n8aLFYHh+frlarSKkBfUuIsO86RAwrKRq+z3dFseO8VkqFfIDgjgDQSvkA4dPbUAE8a14AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f69575330d855de0874ec3b8e714fb3c/e4706/Lense_Chart_2_2mm.avif 230w", "/en/static/f69575330d855de0874ec3b8e714fb3c/d1af7/Lense_Chart_2_2mm.avif 460w", "/en/static/f69575330d855de0874ec3b8e714fb3c/2b06e/Lense_Chart_2_2mm.avif 492w"],
              "sizes": "(max-width: 492px) 100vw, 492px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f69575330d855de0874ec3b8e714fb3c/a0b58/Lense_Chart_2_2mm.webp 230w", "/en/static/f69575330d855de0874ec3b8e714fb3c/bc10c/Lense_Chart_2_2mm.webp 460w", "/en/static/f69575330d855de0874ec3b8e714fb3c/9d6da/Lense_Chart_2_2mm.webp 492w"],
              "sizes": "(max-width: 492px) 100vw, 492px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f69575330d855de0874ec3b8e714fb3c/81c8e/Lense_Chart_2_2mm.png 230w", "/en/static/f69575330d855de0874ec3b8e714fb3c/08a84/Lense_Chart_2_2mm.png 460w", "/en/static/f69575330d855de0874ec3b8e714fb3c/5c6e9/Lense_Chart_2_2mm.png 492w"],
              "sizes": "(max-width: 492px) 100vw, 492px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f69575330d855de0874ec3b8e714fb3c/5c6e9/Lense_Chart_2_2mm.png",
              "alt": "IN-0220 Wideangle",
              "title": "IN-0220 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/66b7f4a407915502b45388169f8cd79f/46b1b/Nachtsicht_2%2C2mm_without_IR_Cut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABj7M98gg//8QAGxAAAwACAwAAAAAAAAAAAAAAAQIDERIABBP/2gAIAQEAAQUCUZJlgaHkn1e3YTyahJ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAASEQYf/aAAgBAQAGPwIuJlvM/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARMSFRYf/aAAgBAQABPyFOCV6atC4koGTwxlXq1iIXuf/aAAwDAQACAAMAAAAQW+//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAn/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARMSFxof/aAAgBAQABPxCl2VcgLfhVj35OLRVKmJ85E6d02LdBStXP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66b7f4a407915502b45388169f8cd79f/e4706/Nachtsicht_2%2C2mm_without_IR_Cut.avif 230w", "/en/static/66b7f4a407915502b45388169f8cd79f/d1af7/Nachtsicht_2%2C2mm_without_IR_Cut.avif 460w", "/en/static/66b7f4a407915502b45388169f8cd79f/5e9eb/Nachtsicht_2%2C2mm_without_IR_Cut.avif 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/66b7f4a407915502b45388169f8cd79f/a0b58/Nachtsicht_2%2C2mm_without_IR_Cut.webp 230w", "/en/static/66b7f4a407915502b45388169f8cd79f/bc10c/Nachtsicht_2%2C2mm_without_IR_Cut.webp 460w", "/en/static/66b7f4a407915502b45388169f8cd79f/7eb86/Nachtsicht_2%2C2mm_without_IR_Cut.webp 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/66b7f4a407915502b45388169f8cd79f/e83b4/Nachtsicht_2%2C2mm_without_IR_Cut.jpg 230w", "/en/static/66b7f4a407915502b45388169f8cd79f/e41a8/Nachtsicht_2%2C2mm_without_IR_Cut.jpg 460w", "/en/static/66b7f4a407915502b45388169f8cd79f/46b1b/Nachtsicht_2%2C2mm_without_IR_Cut.jpg 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/66b7f4a407915502b45388169f8cd79f/46b1b/Nachtsicht_2%2C2mm_without_IR_Cut.jpg",
              "alt": "IN-0220 Wideangle",
              "title": "IN-0220 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.2 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.49 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<  -40.3%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`98.2° x 78.7° x 60.7°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.5 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      